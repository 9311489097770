.Login-MainContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Login-Banner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.Login-Banner h3{
    font-weight: bold;
    margin: 0;
    margin-top: 20px;
}

.Login-InnerContainer{
    display: flex;
    padding: 40px;
    flex-direction: column;
}


.PhoneInput{
    height: (2em + 10px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    border-bottom: 1px solid gray;
    padding: 5px;
    font-size: 1.2em;
}

.PhoneInput:focus-within{
    border-bottom: 2px solid var(--primary-color);
}

.PhoneInput p {
    margin: 0;
    margin-right: 1em;
}

.PhoneInput input{
    height: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    flex: 1;
}

.Login-Instruction{
    color: rgb(92, 92, 92);
    font-size: 1em;
}

/* big screens */
@media (min-width: 600px) {
    .Login-MainContainer{
        flex-direction: row;
    }

    .Login-Banner{
        flex: 1;
        height: 100%;
        min-width: 300px;
    }

    .Login-InnerContainer{
        flex: 3;
        height: 100%;
        align-items: flex-start;
        justify-content: center;
    }

    .Login-Banner img{
        width: 200px;
    }

    .Login-Banner h3{
        font-size: 2.4em;
    }

    .Login-Banner{
        padding-bottom: 10%;
    }

    .PhoneInput{
        width: 300px;
    }
    
    .Login-Instruction{
        text-align: left;
        margin-top: 20px;
        margin-bottom: 100px;
    }

    .Login-SendBtn{
        position: absolute;
        bottom: 40px;
        right: 40px;
    }
}

/* small screens */
@media (max-width: 600px) {
    .Login-MainContainer{
        flex-direction: column;
    }

    .Login-Banner{
        flex: 2;
        width: 100%;
    }

    .Login-InnerContainer{
        flex: 3;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .Login-Banner img{
        width: 120px;
    }

    .Login-Banner h3{
        font-size: 2em;
    }

    .PhoneInput{
        width: 100%;
    }

    .Login-Instruction{
        text-align: center;
    }
}

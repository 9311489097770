.Book-MainContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.Book-Banner{
    width: 100%;
    padding: 15px 20px;
}

.Book-Banner-Title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Book-Banner-Title a{
    color: var(--secondary-color);
    width: 25px;
    height: 25px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
}

.Book-Banner-Title a:hover{
    cursor: pointer;
}

.Book-Banner-Title h4{
    padding: 0;
    margin: 0;
    font-size: 1.8em;
    font-weight: 400;
    vertical-align: middle;
    flex: 1;
    margin-top: -3px;
}

.Book-InnerContainer{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.Book-OptionsContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.Book-OptionsContainer:nth-child(1){
    width: 100% !important;
}

.react-calendar{
    width: 100% !important;
}

.Book-Type{
    width: calc(100% - 10px);
    font-size: 1.2em;
    border: 1px solid rgb(163, 163, 163);
    margin: 5px;
    display: block;
}
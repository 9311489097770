.AppEntry-Main{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid rgb(194, 194, 194);
}

.AppEntry-Main:hover{
    cursor: pointer;
}

.AppEntry-Head{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

.AppEntry-Head h2{
    font-size: 1.4em;
    flex: 1;
    color: black;
    font-weight: 500;
    margin: 0;
}

.AppEntry-Head p{
    font-size: 1.2em;
    font-weight: 300;
    color: black;
    margin: 0;
    margin-left: 20px;
}

.AppEntry-Info{
    width: 100%;
    margin: 0;
    margin-top: 5px;
}
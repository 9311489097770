#exercise-list{
    display:flex;
    justify-content: center;
}
.ExerciseList-Banner-Title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.ExerciseList-Banner-Title h4{
    padding: 0;
    margin: 0;
    font-size: 1.8em;
    font-weight: 400;
    vertical-align: middle;
    flex: 1;
    margin-top: -3px;
}
.ExerciseList-Banner{
    width: 100%;
    padding: 15px 20px;
}
#exercise-item{
    margin:7px;
    border:1px solid gray;
    border-radius: 5px;
  }
.ExerciseList-Banner-Title a{
    color: var(--secondary-color);
    width: 25px;
    height: 25px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
}

.ExerciseList-Banner-Title a:hover{
    cursor: pointer;
}
.ExerciseList{
border:none;
}
.Exercise-Title{
    background-color:darkgray;
}
.Exercise-content{
  display: flex;
  justify-content: center;

}
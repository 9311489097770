.AppointmentList-MainContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.AppointmentList-Banner{
    width: 100%;
    padding: 15px 20px;
}

.AppointmentList-Banner-Title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.AppointmentList-Banner-Title a{
    color: var(--secondary-color);
    width: 25px;
    height: 25px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
}

.AppointmentList-Banner-Title a:hover{
    cursor: pointer;
}

.AppointmentList-Banner-Title h4{
    padding: 0;
    margin: 0;
    font-size: 1.8em;
    font-weight: 400;
    vertical-align: middle;
    flex: 1;
    margin-top: -3px;
}

.AppointmentList-Subtitle{
    width: 100%;
    padding: 10px 20px;
    background-color: rgb(233, 233, 233);
    color: rgb(92, 92, 92);
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
}

.AppointmentList-Book{
    align-self: center;
    font-size: 1.2em;
    font-weight: 500;
    margin: 10px;
}
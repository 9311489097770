.Progress-MainContainer{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.Progress-Dialog{
    width: 80%;
    max-width: 400px;
}

.Progress-Heading{
    width: 100%;
    padding: 10px;
    font-size: 1.2em;
    font-weight: 500;
    margin: 0;
}

.Progress-DialogBody{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    font-style: italic;
}

.Progress-DialogBody img{
    width: 25px;
    height: 25px;
    object-fit: contain;
    animation: rotate 1s linear infinite;
}

.Progress-DialogBody p{
    flex: 1;
    font-size: 1em;
    font-weight: 400;
    color: black;
    margin: 0;
    margin-left: 20px;
}

@keyframes rotate {
    100% {
        transform: rotateZ(360deg);
    }
}
.ConfirmApt-MainContainer{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.ConfirmApt-InnerContainer{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ConfirmApt-Subtitle{
    width: 100%;
    padding: 10px 20px;
    background-color: rgb(233, 233, 233);
    color: rgb(92, 92, 92);
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
}

.ConfirmApt-Subtitle span:hover{
    cursor: pointer;
}

.ConfirmApt-DocInfoContainer{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.ConfirmApt-DocInfoRight{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px 20px;
}

.ConfirmApt-DocInfoRight p{
    margin: 0;
    padding: 0;
    font-size: 1.2em;
    font-weight: 600;
    vertical-align: middle;
}

.ConfirmApt-DocInfoContainer img{
    width: 60px;
    height: 60px;
    border-radius: 50px;
    object-fit: cover;
}

.ConfirmApt-AppointmentRadios{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
}

.ConfirmApt-AppointmentRadios label span{
    font-size: 1.2em;
    font-weight: 400;
    color: black;

}

.ConfirmApt-PrescriptionContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 20px;
}

.ConfirmApt-PrescriptionEntry{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(214, 214, 214);
}

.ConfirmApt-PrescriptionEntry p{
    flex: 1;
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
    padding: 5px;
}

.ConfirmApt-PrescriptionEntry a{
    text-decoration: none;
    color: red;
    font-weight: 700;
    font-size: 1em;
    padding: 0px 10px;
}

.ConfirmApt-PrescriptionEntry a:hover{
    cursor: pointer;
    color: rgb(168, 0, 0);
}

.ConfirmApt-Note{
    width: calc(100% - 40px);
    min-height: 100px;
    margin: 20px;
    padding: 10px;
    resize: vertical;
}

.ConfirmApt-ConfirmBtn{
    width: 100%;
    padding: 15px 20px;
    font-size: 1.2em;
    font-weight: 500;
    border: none;
}

.ConfirmApt-ConfirmBtn:hover{
    cursor: pointer;
}

#item-header{
    list-style-type:none;
    padding-left:80px;
    
}
#list-item-image{
    width: 50px;
    height:50px;
}
#list-item-title{
    font-weight:650;
    font-size:large
}
#list-item-qualification{
    font-weight: 600;
}
#list-item-details{
    width:500px;
}
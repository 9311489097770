html, body, #root{
  width: 100%;
  height: 100%;
}

:root{
  --primary-color: #008080;
  --secondary-color: #FFF;
}

.PrimaryText{
  color: var(--primary-color);
}

.SecondaryText{
  color: var(--secondary-color);
}

.PrimaryBg{
  background-color: var(--primary-color);
}

.SecondaryBg{
  background-color: var(--secondary-color);
}

.PatternBg{
  background-image: url("./Resources/arches.png");
  background-repeat: repeat;
}

body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Clickable{
  cursor: pointer;
}
.ScheduleTable-MainContainer{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.ScheduleTable-BookBtn{
    width: 100%;
    padding: 15px 20px;
    font-size: 1.2em;
    font-weight: 500;
    border: none;
}

.ScheduleTable-BookBtn:hover{
    cursor: pointer;
}

.ScheduleTable-TabView{
    flex: 1;
    padding: 5px 10px;
}

.ScheduleTable-InnerContainer{
    flex: 1;
    width: 100%;
}

.ScheduleTable-Tabs .tab a{
    color: gray;
} /*Black color to the text*/

.ScheduleTable-Tabs .tab a:hover {
    background-color:rgb(174, 255, 224);
    color: var(--primary-color);
} /*Text color on hover*/

.ScheduleTable-Tabs .tab a.active {
    color: var(--primary-color);
} /*Background and text color when a tab is active*/

.ScheduleTable-Tabs .indicator {
    background-color:var(--primary-color);
} /*Color of underline*/

.ScheduleTable-Schedule{
    width: 100%;
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: auto auto auto auto;
}


.ScheduleTable-Schedule .Slot{
    padding: 5px;
    border: 1px solid var(--primary-color);
    text-align: center;
    vertical-align: middle;
    font-size: 0.8em;
    font-weight: 400;
    color: var(--primary-color);
    margin: 0;
}

.ScheduleTable-Schedule .SlotSelected{
    padding: 5px;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    text-align: center;
    vertical-align: middle;
    font-size: 0.8em;
    font-weight: 400;
    color: var(--secondary-color);
    margin: 0;
}

.ScheduleTable-Schedule .SlotDisabled{
    padding: 5px;
    border: 1px solid gray;
    text-align: center;
    vertical-align: middle;
    font-size: 0.8em;
    font-weight: 400;
    color: gray;
    margin: 0;
}

.ScheduleTable-Schedule:nth-child(1):hover{
    cursor: pointer;
}